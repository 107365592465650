.carousel {
    position: relative;
    margin: 0 calc(50% - 50vw);
}

/* stylelint-disable max-nesting-depth */
.carousel__list {
    @include delist;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include scrollable-x;
    scroll-snap-type: x proximity;

    .carousel--with-gaps & {
        scroll-padding-left: $pageGutterMobile;

        &::after {
            width: $pageGutterMobile;
            flex-shrink: 0;

            content: '';
        }
    }

    .carousel--align-top & {
        align-items: flex-start;
    }

    @include media('>=tablet') {
        .carousel--with-gaps & {
            &::after {
                width: $pageGutterTablet;
            }
        }
    }

    @include media('>=desktop') {
        .carousel--with-gaps & {
            &::after {
                width: $pageGutterDesktop;
            }
        }
    }
}

.carousel__item {
    min-width: calc(100% - #{$pageGutterMobile * 2});

    scroll-snap-align: center;

    .carousel--with-gaps & {
        margin-left: $gridGutterMobile;
        min-width: calc(100% - #{$gridGutterMobile * 2});
    }

    .carousel--with-gaps &:first-child {
        margin-left: $pageGutterMobile;
    }

    .carousel--logos & {
        min-width: 240px;
        flex: 1 1 auto;
        margin-left: $gridGutterMobile;

        &:first-child {
            margin-left: $pageGutterMobile;
        }
    }

    @include media('>=tablet') {
        min-width: 33.33%;

        .carousel--with-gaps & {
            margin-left: $gridGutterTablet;
            min-width: calc(50% - #{$gridGutterTablet * 2});
        }

        .carousel--with-gaps &:first-child {
            margin-left: $pageGutterTablet;
        }

        .carousel--logos & {
            margin-left: $gridGutterTablet;

            &:first-child {
                margin-left: $pageGutterTablet;
            }
        }
    }

    @include media('>=desktop') {
        min-width: 27.5%;

        .carousel--with-gaps & {
            margin-left: $gridGutterDesktop;
            min-width: 27.5%;
        }

        .carousel--with-gaps &:first-child {
            margin-left: $pageGutterDesktop;
        }

        .carousel--logos & {
            margin-left: $gridGutterDesktop;

            &:first-child {
                margin-left: $pageGutterDesktop;
            }
        }
    }
}
/* stylelint-enable max-nesting-depth */

.carousel__figure {
    margin: 0;
}

.carousel__image,
.carousel__image > img {
    display: block;
    width: 100%;
    height: auto;
}

.carousel__caption {
}

.carousel__controls {
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    height: 0;

    color: $white;

    .no-js & {
        display: none;
    }

    @include media('>=desktop') {
        .carousel--high-arrows & {
            top: 25%;
        }

        .carousel--logos & {
            top: 60%;
        }
    }
}

.carousel__button {
    @include unbutton;
    position: absolute;
    top: 0;
    margin-top: -10px;

    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }

    .icon {
        width: 35px;
        height: 20px;
    }

    &--prev {
        left: 0;

        .icon {
            transform: rotateZ(90deg);
        }
    }

    &--next {
        right: 0;

        .icon {
            transform: rotateZ(-90deg);
        }
    }

    .carousel--high-arrows &::before,
    .carousel--logos &::before {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;

        background: rgba($black, 0.075);

        border-radius: 50%;

        transform: translate(-50%, -50%);
        filter: blur(10px);
        content: '';
    }

    @include media('>=desktop') {
        margin-top: -35px;

        .icon {
            width: 70px;
            height: 40px;
        }

        &--prev {
            left: 10px;
        }

        &--next {
            right: 10px;
        }

        .carousel--high-arrows &::before,
        .carousel--logos &::before {
            width: 80px;
            height: 80px;
        }
    }
}
