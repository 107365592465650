@use 'sass:math';

.card-grid {
}

.card-grid__list {
    @include delist;
    display: flex;
    flex-flow: row wrap;
    gap: $gridGutterMobile * 3 $gridGutterMobile;

    @include media('>=tablet') {
        gap: $gridGutterTablet * 3 $gridGutterTablet;

        .no-flex-gap & {
            margin: 0 #{$gridGutterTablet * -0.5};
        }
    }

    @include media('>=desktop') {
        gap: $gridGutterDesktop * 3 $gridGutterDesktop;

        .no-flex-gap & {
            margin: 0 #{$gridGutterDesktop * -0.5};
        }
    }
}

.card-grid__item {
    flex-basis: 100%;

    @include media('<tablet') {
        .no-flex-gap & + & {
            margin-top: $gridGutterMobile * 3;
        }
    }

    @include media('>=tablet') {
        flex-basis: calc(50% - (#{$gridGutterTablet} * 1 / 2));

        .no-flex-gap & {
            margin: calc(#{$gridGutterTablet} * 3 / 2) #{$gridGutterTablet *
                0.25};
        }
    }

    @include media('>=desktop') {
        flex-basis: calc(33.33% - (#{$gridGutterDesktop} * 2 / 3));

        .no-flex-gap & {
            margin: calc(#{$gridGutterDesktop} * 3 / 2) #{$gridGutterDesktop *
                0.25};
        }
    }
}
