.footer {
    background: $darkGray;

    text-align: center;
    color: $white;
}

.footer__pre {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: $lighterGray;
}

.footer__inner {
    @include clamp;
    padding: 60px 0;

    @include media('>=tablet') {
        padding: 50px 0 20px 0;
    }
}

.footer__grid {
    @include grid;
    grid-template-areas:
        'logo logo'
        'address address'
        'other other'
        'nav nav'
        'copyright copyright';

    @include media('>=tablet') {
        grid-template-areas:
            'logo logo logo logo logo logo'
            'address address address other other other'
            'copyright copyright copyright nav nav nav';
    }

    @include media('>=desktop') {
        grid-template-areas:
            '. . logo logo logo logo logo logo logo logo . .'
            '. . address address address address other other other other . .'
            '. . copyright copyright copyright copyright nav nav nav nav . .';
    }
}

.footer__logo {
    grid-area: logo;
}

.footer__logo__link {
    @include hide-text-alt;
    display: block;
    width: 185px;
    height: 122px;
    margin: 0 auto;

    background: url('../svg/logo_white.svg') 0 0 no-repeat;
    background-size: 100% auto;
}

.footer__street-address {
    grid-area: address;
    margin: #{30px - $gridGutterMobile} 0 0 0;

    @include media('>=tablet') {
        margin-top: #{30px - $gridGutterDesktop};

        text-align: right;
    }
}

.footer__other-contacts {
    @include delist;
    grid-area: other;
    margin: #{45px - $gridGutterMobile} 0 0 0;

    @include media('>=tablet') {
        margin-top: #{30px - $gridGutterDesktop};

        text-align: left;
    }
}

.footer__other-contacts__item {
    & + & {
        margin-top: 10px;
    }
}

.footer__other-contacts__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon {
        flex-shrink: 0;
        margin: 0 5px 0 0;
    }

    &--large:first-child {
        margin-top: -12px;
    }

    &--large .icon {
        width: 53px;
        height: 53px;
        margin: 0 0 0 15px;
    }

    @include media('>=tablet') {
        justify-content: flex-start;
    }
}

.footer__nav {
    grid-area: nav;
    margin: #{70px - $gridGutterMobile} 0 0 0;

    @include media('>=tablet') {
        margin-top: #{60px - $gridGutterDesktop};

        text-align: left;
    }
}

.footer__nav__list {
    @include delist;
    display: flex;
    flex-flow: row wrap;
    gap: 10px 20px;

    @include media('<tablet') {
        justify-content: center;
    }
}

.footer__nav__item {
    .no-flex-gap & + & {
        margin-left: 20px;
    }
}

.footer__nav__link {
}

.footer__copyright {
    grid-area: copyright;
    margin: #{40px - $gridGutterMobile} 0 0 0;

    @include media('>=tablet') {
        margin-top: #{60px - $gridGutterDesktop};

        text-align: right;
    }
}

.footer__copyright__name {
    @include media('<tablet') {
        display: block;
    }
}
