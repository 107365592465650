.animation-observer-sentinel {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 50%;
    min-height: 20px;
    max-height: 50vh;

    &--bottom {
        top: auto;
        bottom: 0;
    }
}

@include media('>=tablet') {
    .js [data-component~='animation-observer'] [data-ref~='animate'] {
        opacity: 0;
        transform: translateY(10px);
        transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;

        &.is-animating-in {
            opacity: 1;
            transform: none;
        }
    }
}

@media (prefers-reduced-motion) {
    .js [data-component~='animation-observer'] [data-ref~='animate'] {
        opacity: 1 !important;
        transform: none !important;
    }
}
