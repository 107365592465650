/* montserrat-300 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: $fontWeightLight;
    src:
        local(''),
        url('../fonts/montserrat-v23-latin-300.woff2') format('woff2'),
        url('../fonts/montserrat-v23-latin-300.woff') format('woff');
    font-display: swap;
}

/* montserrat-500 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: $fontWeightMedium;
    src:
        local(''),
        url('../fonts/montserrat-v23-latin-500.woff2') format('woff2'),
        url('../fonts/montserrat-v23-latin-500.woff') format('woff');
    font-display: swap;
}

/* montserrat-600 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: $fontWeightSemibold;
    src:
        local(''),
        url('../fonts/montserrat-v23-latin-600.woff2') format('woff2'),
        url('../fonts/montserrat-v23-latin-600.woff') format('woff');
    font-display: swap;
}

/* montserrat-300italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: $fontWeightLight;
    src:
        local(''),
        url('../fonts/montserrat-v23-latin-300italic.woff2') format('woff2'),
        url('../fonts/montserrat-v23-latin-300italic.woff') format('woff');
    font-display: swap;
}

/* montserrat-500italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: $fontWeightMedium;
    src:
        local(''),
        url('../fonts/montserrat-v23-latin-500italic.woff2') format('woff2'),
        url('../fonts/montserrat-v23-latin-500italic.woff') format('woff');
    font-display: swap;
}

/* montserrat-600italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: $fontWeightSemibold;
    src:
        local(''),
        url('../fonts/montserrat-v23-latin-600italic.woff2') format('woff2'),
        url('../fonts/montserrat-v23-latin-600italic.woff') format('woff');
    font-display: swap;
}

/* playfair-display-regular - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: $fontWeightRegular;
    src:
        local(''),
        url('../fonts/playfair-display-v28-latin-regular.woff2') format('woff2'),
        url('../fonts/playfair-display-v28-latin-regular.woff') format('woff');
    font-display: swap;
}

/* playfair-display-700 - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: $fontWeightBold;
    src:
        local(''),
        url('../fonts/playfair-display-v28-latin-700.woff2') format('woff2'),
        url('../fonts/playfair-display-v28-latin-700.woff') format('woff');
    font-display: swap;
}

/* playfair-display-italic - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: $fontWeightRegular;
    src:
        local(''),
        url('../fonts/playfair-display-v28-latin-italic.woff2') format('woff2'),
        url('../fonts/playfair-display-v28-latin-italic.woff') format('woff');
    font-display: swap;
}

/* playfair-display-700italic - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: $fontWeightBold;
    src:
        local(''),
        url('../fonts/playfair-display-v28-latin-700italic.woff2') format('woff2'),
        url('../fonts/playfair-display-v28-latin-700italic.woff') format('woff');
    font-display: swap;
}
