/* stylelint-disable max-nesting-depth */
.u-rich-text {
    blockquote {
        @extend .t-h4; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */
        margin: 0;

        &::before,
        &::after {
            display: block;
            height: 38px;

            font-weight: $fontWeightBold;
            font-size: 120px;
            line-height: 1;
            transform: translateY(-50%);
        }

        &::before {
            margin-bottom: 10px;

            content: '“';
        }

        &::after {
            margin-top: 10px;

            content: '”';
        }

        + p > cite { /* stylelint-disable-line selector-max-compound-selectors */
            display: block;
            margin-top: -0.1em;

            font-style: normal;
        }
    }

    ul {
        list-style-type: disc;

        ul {
            list-style-type: circle;
        }
    }

    figure {
        margin: 0;
        padding: 0;

        > img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    @include media('>=tablet') {
        blockquote {
            display: flex;
            flex-direction: row;
            gap: 20px;

            &::before,
            &::after {
                flex-shrink: 0;
                height: 75px;

                font-size: 240px;
            }

            &::before {
                align-self: flex-start;
            }

            &::after {
                align-self: flex-end;
            }
        }

        figure {
            float: left;
            width: 50%;
            margin: 0 1.75em 1.5em 0;
            padding: 0;
        }
    }

    @include media('>=desktop') {
        blockquote {
            gap: 30px;
            margin-left: auto;
            margin-right: auto;
            max-width: 1050px;
        }
    }
}

.u-gray {
    color: $midGray;
}
/* stylelint-enable max-nesting-depth */
