.person-card {
    height: 100%;

    text-align: left;
}

.person-card__link {
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.person-card__image,
.person-card__image > img {
    display: block;
    width: 100%;
    height: auto;
    flex-shrink: 0;
}

.person-card__placeholder {
    width: 100%;
    aspect-ratio: 320 / 266;
    flex-shrink: 0;

    background: $lighterGray;
}

.person-card__placeholder,
.person-card__image {
    margin-bottom: 20px;
}

.person-card__title {
    margin: 0 0 5px 0;

    @include media('>=desktop') {
        margin-bottom: 8px;
    }
}

.person-card__role {
    margin: 0;

    color: $midGray;

    &.is-highlighted {
        color: $orange;
    }
}

.person-card__bio {
    margin-top: 10px;
    flex-grow: 1;

    @include media('>=desktop') {
        margin-top: 20px;
    }
}

.person-card__ctas {
    margin-top: 20px;

    color: $green;
}
