.in-page-nav {
    background: $darkGray;

    border-top: 2px solid;

    text-align: center;
    color: $white;

    @include media('>=tablet') {
        position: sticky;
        z-index: $z-in-page-nav;
        top: $stickyHeaderHeightTablet;
    }

    @include media('>=desktop') {
        top: $stickyHeaderHeightDesktop;
    }
}

.in-page-nav__inner {
    @include clamp;
    @include gutter-padding;
    padding-top: 20px;
    padding-bottom: 20px;

    @include media('>=tablet') {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

.in-page-nav__nav {
}

.in-page-nav__list {
    @include delist;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 10px 20px;
}

.in-page-nav__item {
    .no-flex-gap & + & {
        margin-left: 20px;
    }
}

.in-page-nav__link {
    text-underline-offset: 2px;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }

    &.is-active {
        text-decoration: underline;
        text-decoration-color: $orange;
    }
}
