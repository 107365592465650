.button {
    @include unbutton;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    position: relative;

    border: 1px solid;

    text-decoration: none;
    text-align: left;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background: currentColor;

            opacity: 0.05;
            content: '';
        }
    }

    .icon {
        width: 21px;
        height: 15px;

        &:first-child {
            margin: 0 10px 0 0;
        }

        &:last-child {
            margin: 0 0 0 10px;
        }
    }

    &--large {
        padding: 12px 15px;

        border-width: 3px;

        .icon {
            width: 40px;
            height: 21px;
        }
    }

    &--inline {
        padding: 0;

        border: 0;
    }

    &--special {
        padding: 5px 20px;

        background: $orange;

        border: 0;

        color: $white !important;
    }

    &--orange {
        color: $orange;
    }
}
