html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font: $fontWeightMedium #{$baseFontSize}/$baseLineHeightRatio $fontStackBody;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;

    // XXX: Account for sticky header height
    scroll-padding-top: $stickyHeaderHeightMobile;

    @include media('>=tablet') {
        scroll-padding-top: $stickyHeaderHeightTablet;

        /* stylelint-disable-next-line selector-no-qualifying-type */
        &.has-in-page-nav {
            scroll-padding-top: $stickyHeaderWithInPageNavHeightTablet;
        }
    }

    @include media('>=desktop') {
        scroll-padding-top: $stickyHeaderHeightDesktop;

        /* stylelint-disable-next-line selector-no-qualifying-type */
        &.has-in-page-nav {
            scroll-padding-top: $stickyHeaderWithInPageNavHeightDesktop;
        }
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    background: $white;

    color: $darkGray;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
}

audio {
    border-radius: 50vw;
}
