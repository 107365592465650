.practice-area-card {
    display: flex;
    flex-direction: column;

    @include media('>=tablet') {
        gap: $gridGutterTablet;
    }

    @include media('>=desktop') {
        flex-direction: row;
        gap: $gridGutterDesktop;
    }
}

.practice-area-card__image-wrapper {
    @include media('<tablet') {
        margin-left: #{$pageGutterMobile * -1};
        margin-right: #{$pageGutterMobile * -1};
    }

    @include media('>=tablet') {
        margin: 0;
    }

    @include media('>=desktop') {
        flex-basis: calc(50% - (#{$gridGutterDesktop} * (1 / 2)));
    }
}

.practice-area-card__image,
.practice-area-card__image > img {
    display: block;
    width: 100%;
    height: auto;
}

.practice-area-card__content {
    @include media('<desktop') {
        margin-top: 20px;
    }

    @include media('>=desktop') {
        flex-basis: calc(50% - (#{$gridGutterDesktop} * (1 / 2)));
        display: flex;
        flex-direction: column;

        &:first-child:last-child {
            flex-basis: 100%;
        }
    }
}

.practice-area-card__excerpt {

}

.practice-area-card__aspects {
    margin-top: 20px;

    color: $orange;

    @include media('>=desktop') {
        flex-grow: 1;
    }
}

.practice-area-card__ctas {
    margin-top: 20px;

    @include media('>=tablet') {
        margin-top: 40px;
    }
}
