.image-grid {
}

.image-grid__list {
    @include delist;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gridGutterMobile * 2;

    @include media('>=tablet') {
        flex-flow: row wrap;
        align-items: flex-start;
        gap: $gridGutterTablet * 3 $gridGutterTablet;

        .no-flex-gap & {
            margin: #{$gridGutterTablet * -0.5};
        }
    }

    @include media('>=desktop') {
        gap: $gridGutterDesktop * 3 $gridGutterDesktop;

        .no-flex-gap & {
            margin: #{$gridGutterDesktop * -0.5};
        }
    }
}

.image-grid__item {
    @include media('<tablet') {
        .no-flex-gap & + & {
            margin-top: $gridGutterMobile * 2;
        }
    }

    @include media('>=tablet') {
        flex-basis: calc(50% - (#{$gridGutterTablet} * 1 / 2));

        .image-grid--single & {
            flex-basis: 100%;
        }

        .image-grid--double & {
            flex-basis: calc(50% - (#{$gridGutterTablet} * 1 / 2));
        }

        .image-grid--triple & {
            flex-basis: calc(33.33% - (#{$gridGutterTablet} * 2 / 3));
        }

        .no-flex-gap & {
            margin: calc(#{$gridGutterTablet} * 3 / 2) #{$gridGutterTablet *
                0.25};
        }
    }

    @include media('>=desktop') {
        flex-basis: calc(25% - (#{$gridGutterDesktop} * 3 / 4));

        .image-grid--double & {
            flex-basis: calc(50% - (#{$gridGutterDesktop} * 1 / 2));
        }

        .image-grid--triple & {
            flex-basis: calc(33.33% - (#{$gridGutterDesktop} * 2 / 3));
        }

        .no-flex-gap & {
            margin: calc(#{$gridGutterDesktop} * 3 / 2) #{$gridGutterDesktop *
                0.25};
        }
    }
}

.image-grid__figure {
    margin: 0;
}

.image-grid__image,
.image-grid__image > img {
    display: block;
    width: 100%;
    height: auto;
}

.image-grid__caption {
    margin-top: 20px;

    @include media('>=tablet') {
        margin-top: 30px;
    }
}
