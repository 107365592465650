.simple-page {
    text-align: left;
}

.simple-page__inner {
    @include clamp;
    @include gutter-padding;
    padding-top: 50px;
    padding-bottom: 75px;

    @include media('>=desktop') {
        padding-top: 100px;
        padding-bottom: 150px;
    }
}

.simple-page__header {

}

.simple-page__title {
    margin: 0 0 40px 0;

    color: $orange;
}

.simple-page__body {

}
