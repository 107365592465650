.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-header;

    color: $white;
    transition: all 0.25s ease-in-out;

    &.is-sticky,
    .no-js & {
        background: $darkGray;

        border-bottom: 1px solid $white;
    }

    .has-forced-sticky-header & {
        position: sticky;

        background: $darkGray;

        border-bottom: 1px solid $white;
    }

    @include media('>=tablet', '<desktop') {
        position: sticky;

        background: $darkGray;

        border-bottom: 1px solid $white;
    }
}

.header__inner {
    @include clamp;
    @include gutter-padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;

    @include media('>=tablet') {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

.header__logo {
    flex-shrink: 0;
}

.header__logo__link {
    @include hide-text-alt;
    display: block;
    width: 66px;
    height: 43px;
    margin: 0;
    position: relative;

    background: url('../svg/logo_white.svg') 0 0 no-repeat;
    background-size: 100% auto;

    outline: 0;

    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.7;
    }

    @include media('>=tablet') {
        width: 114px;
        height: 74px;
    }
}

.header__nav {
    @include media('<desktop') {
        display: none;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        top: 0;
        right: 0;

        background: $darkGray;
        box-shadow: 0 4px 4px 0 rgba($black, 25%);

        .header.is-open & {
            display: flex;
        }
    }

    @include media('>=desktop') {
        flex-grow: 1;
        margin: 0 70px;
    }
}

.header__nav__close {
    @include unbutton;
    margin: 10px $pageGutterMobile 10px auto;

    .icon {
        width: 22px;
        height: 22px;
    }

    @include media('>=tablet') {
        margin: 25px $pageGutterTablet 25px auto;

        .icon {
            width: 40px;
            height: 40px;
        }
    }

    @include media('>=desktop') {
        display: none;
    }
}

.header__nav__list {
    @include delist;
    padding: 0 0 15px 0;

    @include media('>=desktop') {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 0;
        gap: 20px;
    }
}

.header__nav__item {
    display: block;
    position: relative;
    z-index: 1;

    .no-flex-gap & {
        margin: 0 10px;
    }

    @include media('>=desktop') {
        &:hover,
        &:focus-within,
        &.focus-within {
            z-index: 2;
        }

        .no-flex-gap & + & {
            margin-left: 20px;
        }
    }
}

.header__nav__link {
    display: block;
    padding: 8px 15px 8px 30px;
    position: relative;
    z-index: 2;

    outline: 0;

    @include media('<desktop') {
        &:hover,
        &:active,
        &:focus,
        &.is-active {
            background: $white;

            text-decoration: none;
            color: $darkGray;
        }
    }

    @include media('>=tablet') {
        padding: 15px 30px;
    }

    @include media('>=desktop') {
        padding: 0;

        text-align: center;
        text-underline-offset: 0.25em;

        &.is-active {
            text-decoration: underline;
            text-decoration-color: $orange;
        }
    }
}

.header__subnav {
    $pad: 10px;

    display: none;
    position: absolute;
    top: $pad * -1;
    left: $pad * -1;
    z-index: 1;
    padding: calc(#{$pad} + 1em + #{$pad * 2}) #{$pad} #{$pad} #{$pad};
    min-width: calc(100% + #{$pad});
    max-width: 200px;
    width: 200px;
    width: max-content;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    background: rgba($darkGray, 0.9);

    text-align: left;
    color: $white;

    @include media('>=desktop') {
        .header__nav__item:hover &,
        .header__nav__item:focus-within &,
        .header__nav__item.focus-within & {
            display: flex;
        }
    }
}

.header__subnav__item {
    .no-flex-gap & {
        margin-top: 10px;
    }
}

.header__subnav__link {
    outline: 0;

    text-transform: none;

    &:hover,
    &:active,
    &:focus,
    &.is-active {
        text-decoration: underline;
    }

    &.is-active {
        text-decoration-color: $orange;
    }
}

.header__contact {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 20px;

    @include media('>=tablet') {
        gap: 50px;
    }

    @include media('>=desktop') {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
    }
}

.header__contact__phone {
    display: flex;
    flex-direction: row;
    align-items: center;

    outline: 0;

    .icon {
        width: 16px;
        height: 22px;
    }

    @include media('<tablet') {
        .no-flex-gap & {
            margin-left: 20px;
        }
    }

    @include media('>=tablet', '<desktop') {
        .icon {
            width: 31px;
            height: 43px;
        }

        .no-flex-gap & {
            margin-left: 50px;
        }
    }

    @include media('>=desktop') {
        text-underline-offset: 0.25em;
    }
}

.header__contact__phone__label {
    display: none;

    @include media('>=desktop') {
        display: inline;
        margin-left: 5px;
    }
}

.header__contact__cta {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.7;
    }

    @include media('<desktop') {
        display: none;
    }
}

.header__contact__open {
    @include unbutton;
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
        width: 20px;
        height: 22px;
    }

    @include media('>=tablet') {
        .icon {
            width: 39px;
            height: 36px;
        }
    }

    @include media('>=desktop') {
        display: none;
    }
}
