.practice-area-section {
    &--alt-bg {
        background: $lighterGray;
    }

    & + &::before {
        @include clamp;
        @include gutter-margin;
        display: block;

        border-bottom: 3px dashed $midGray;

        content: '';
    }
}

.practice-area-section__inner {
    @include clamp;
    @include gutter-padding;
    padding-top: 50px;
    padding-bottom: 50px;

    @include media('>=desktop') {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.practice-area-section__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: 0;

    .icon {
        flex-shrink: 0;
        width: 35px;
        height: 35px;

        .no-flex-gap & {
            margin-right: 20px;
        }
    }

    &--highlight {
        color: $green;
    }

    @include media('>=tablet') {
        .icon {
            width: 70px;
            height: 70px;
        }
    }
}

.practice-area-section__aspects {
    margin-top: 30px;
}

.practice-area-section__body {
    margin-top: 30px;

    @include media('>=tablet') {
        margin-top: 50px;
    }
}
