.t-h1 {
    font-family: $fontStackHeading;
    font-weight: $fontWeightRegular;
    font-size: 40px;
    line-height: 1;

    @include media('>=tablet') {
        font-size: 60px;
    }
}

.t-h2 {
    font-family: $fontStackBody;
    font-weight: $fontWeightLight;
    font-size: 40px;
    line-height: 1;

    @include media('>=tablet') {
        font-size: 58px;
    }
}

.t-h3 {
    font-family: $fontStackHeading;
    font-weight: $fontWeightRegular;
    font-size: 27px;
    line-height: 1;

    @include media('>=tablet') {
        font-size: 48px;
    }
}

.t-h4 {
    font-family: $fontStackHeading;
    font-weight: $fontWeightRegular;
    font-size: 30px;
    line-height: 1;

    @include media('>=tablet') {
        font-size: 44px;
    }
}

.t-h5 {
    font-family: $fontStackHeading;
    font-weight: $fontWeightRegular;
    font-size: 24px;
    line-height: 1;

    @include media('>=tablet') {
        font-size: 30px;
    }
}

.t-h6 {
    font-family: $fontStackBody;
    font-weight: $fontWeightSemibold;
    font-size: 19px;
    text-transform: uppercase;

    @include media('>=tablet') {
        font-size: 24px;
    }
}

.t-h7 {
    font-family: $fontStackBody;
    font-weight: $fontWeightMedium;
    font-size: 19px;
    text-transform: uppercase;

    @include media('>=tablet') {
        font-size: 24px;
    }
}

.t-body {
    font-family: $fontStackBody;
    font-weight: $fontWeightMedium;
    font-size: 20px;

    @include media('>=tablet') {
        font-size: 26px;
    }
}

.t-body-bold {
    font-family: $fontStackBody;
    font-weight: $fontWeightSemibold;
    font-size: 20px;

    @include media('>=tablet') {
        font-size: 26px;
    }
}

.t-nav,
.t-in-page-nav {
    font-family: $fontStackBody;
    font-weight: $fontWeightSemibold;
    font-size: 18px;
    text-transform: uppercase;

    @include media('>=tablet') {
        font-size: 28px;
    }

    @include media('>=desktop') {
        font-size: 16px;
    }
}

.t-in-page-nav {
    font-weight: $fontWeightMedium;
    text-transform: none;
}

.t-nav-cta {
    font-family: $fontStackBody;
    font-weight: $fontWeightMedium;
    font-size: 16px;
    text-transform: none;
}

.t-tabs {
    font-family: $fontStackBody;
    font-weight: $fontWeightMedium;
    font-size: 20px;

    @include media('>=tablet') {
        font-size: 26px;
    }

    @include media('>=desktop') {
        font-size: 22px;
    }
}

.t-person-overview-title {
    font-family: $fontStackHeading;
    font-weight: $fontWeightBold;
    font-size: 30px;
    line-height: 1;

    @include media('>=tablet') {
        font-size: 44px;
    }
}

.t-copyright {
    font-family: $fontStackBody;
    font-weight: $fontWeightMedium;
    font-size: 19px;
    text-transform: uppercase;

    @include media('>=tablet') {
        font-size: 14px;
    }
}
