.tabbed-content {
    background: $darkGray;

    color: $white;
}

.tabbed-content__inner {
    @include clamp;
    @include gutter-padding;
    padding-top: 30px;
    padding-bottom: 60px;

    @include media('>=tablet') {
        padding-top: 40px;
        padding-bottom: 80px;
    }

    @include media('>=desktop') {
        padding-top: 40px;
        padding-bottom: 80px;
    }
}

.tabbed-content__header {
}

.tabbed-content__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 0 0 15px 0;

    .icon {
        flex-shrink: 0;
        width: 70px;
        height: 70px;

        color: $orange;

        .no-flex-gap & {
            margin-bottom: 15px;
        }
    }

    @include media('>=tablet') {
        margin-bottom: 20px;
    }
}

.tabbed-content__nav {
}

.tabbed-content__nav__list {
    @include delist;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 40px 0 0 0;
    padding: 0 0 5px 0;

    &:first-child {
        margin-top: 0;
    }

    @include media('>=tablet') {
        flex-flow: row wrap;
    }

    @include media('>=desktop') {
        margin-top: 20px;
        padding: 0;
        justify-content: center;
    }
}

.tabbed-content__nav__item {
    @include media('<tablet') {
        .no-flex-gap & + & {
            margin-top: 20px;
        }
    }

    @include media('>=tablet') {
        .no-flex-gap & + & {
            margin-left: 20px;
        }
    }
}

.tabbed-content__nav__button {
    @include unbutton;

    white-space: nowrap;
    text-underline-offset: 0.25em;

    &:hover,
    &:active,
    &:focus,
    &.is-active {
        text-decoration: underline;
        color: $orange;
    }
}

.tabbed-content__body {
    margin-top: 30px;

    @include media('>=desktop') {
        margin-top: 70px;
    }
}

.tabbed-content__body__list {
    @include delist;

    @include media('>=desktop') {
        display: flex;
        flex-direction: row;
    }
}

.tabbed-content__body__item {
    @include media('<desktop') {
        display: none;

        &.is-active {
            display: block;
        }
    }

    @include media('>=desktop') {
        visibility: hidden;
        margin-right: -100%;
        width: 100%;

        &.is-active {
            visibility: visible;
        }
    }
}
