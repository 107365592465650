.paginator {
    margin: 20px 0 0 0;

    color: $orange;

    @include media('>=desktop') {
        margin-top: 50px;
    }
}

.paginator__list {
    @include delist;
    display: flex;
    flex-direction: row;
}

.paginator__item {
    flex: 1 1 auto;

    &:first-child {
        text-align: left;
    }

    &:last-child {
        text-align: right;
    }
}

.paginator__link {

}
