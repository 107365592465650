.article-promo {
    background: $darkGray;

    color: $white;

    &--accent-green {
        background: $green;
    }

    &--accent-orange {
        background: $orange;
    }
}

.article-promo__inner {
    @include clamp;

    @include media('>=desktop') {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.article-promo__content {
    @include gutter-padding($vertical: true);

    @include media('>=desktop') {
        flex: 0 0 50%;
        padding-top: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
    }
}

.article-promo__title {
    margin: 0;
}

.article-promo__subtitle {
    margin: 20px 0 0 0;

    @include media('>=tablet') {
        margin-top: 40px;
    }

    @include media('>=desktop') {
        margin-top: 50px;
    }
}

.article-promo__standfirst {
    margin: 20px 0 0 0;

    @include media('>=tablet') {
        margin-top: 40px;
    }

    @include media('>=desktop') {
        margin-top: 20px;
    }
}

.article-promo__footer {
    margin: 20px 0 0 0;

    @include media('>=tablet') {
        margin-top: 40px;
    }

    @include media('>=desktop') {
        margin-top: 20px;
    }
}

.article-promo__image-wrapper {
    @include media('>=desktop') {
        flex: 0 0 50%;
    }
}

.article-promo__image,
.article-promo__image > img {
    display: block;
    width: 100%;
    height: auto;
}
