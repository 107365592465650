.image-and-quote {
    @include clamp;
    position: relative;

    &--full {

    }

    &--half {
        background: $darkGray;

        color: $white;
    }

    @include media('<desktop') {
        text-align: center;
    }

    @include media('>=desktop') {
        color: $white;
    }
}

.image-and-quote--half.image-and-quote--accent-green {
    background: $green;
}

.image-and-quote--half.image-and-quote--accent-orange {
    background: $orange;
}

.image-and-quote__inner {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    @include media('>=desktop') {
        .image-and-quote--half & {
            flex-direction: row;
        }
    }
}

.image-and-quote__image,
.image-and-quote__image > img {
    display: block;
    width: 100%;
    height: auto;
}

.image-and-quote__image {
    @include media('<desktop') {
        .image-and-quote--full & {
            order: 2;
        }
    }

    @include media('>=desktop') {
        .image-and-quote--half & {
            flex: 0 0 50%;
        }
    }
}

.image-and-quote__overlay {
    display: none;
    @include overlay-background;

    @include media('>=desktop') {
        .image-and-quote--full & {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}

.image-and-quote__content {
    @include gutter-padding($vertical: true);
    margin: 0;
    display: flex;
    flex-direction: column;

    @include media('<desktop') {
        .image-and-quote--full & {
            order: 1;

            background: $lighterGray;
        }
    }

    @include media('>=desktop') {
        .image-and-quote--half & {
            padding: 50px;
            flex: 0 0 50%;
            justify-content: center;
            align-items: center;

            text-align: center;
        }

        .image-and-quote--full & {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }
}

.image-and-quote__quote {
    margin: 0 auto 0 0 !important;
    padding: 0;
    max-width: 800px !important;

    + p {
        margin-bottom: 0;
    }
}

.image-and-quote__citation {
    width: 100%;
    max-width: 800px;
}

.image-and-quote__cta {
    margin-top: 20px;

    @include media('>=tablet') {
        margin-top: 40px;
    }
}
