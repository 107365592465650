.news-item {
    text-align: left;
}

.news-item__inner {
    @include clamp;
    @include gutter-padding;
    padding-top: 50px;
    padding-bottom: 75px;

    @include media('>=desktop') {
        padding-top: 100px;
        padding-bottom: 150px;
    }
}

.news-item__header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 30px 40px;
}

.news-item__back-link-wrapper {
    flex-basis: 100%;
}

.news-item__back-link {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: $midGray;

    .icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;

        transform: rotateZ(180deg);
    }
}

.news-item__title {
    margin: 0;
    flex-basis: 100%;

    .no-flex-gap & {
        margin: 30px 0;
    }
}

.news-item__date {
}

.news-item__type {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
        width: 30px;
        height: 30px;
        margin-right: 15px;
    }

    .no-flex-gap & {
        margin-left: 40px;
    }
}

.news-item__main {
    margin-top: 40px;

    picture,
    img {
        display: block;
        width: 100%;
        height: auto;
    }

    > div + div {
        margin-top: 40px;
    }

    @include media('>=desktop') {
        margin-top: 60px;

        > div + div {
            margin-top: 60px;
        }
    }
}
