.person-overview {
}

.person-overview__inner {
    @include clamp;
    @include gutter-padding;
    padding-top: 30px;
    padding-bottom: 30px;

    @include media('>=desktop') {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.person-overview__sidebar {
    @include media('>=desktop') {
        float: right;
        width: calc(50% - (#{$gridGutterDesktop} / 2));
        margin: 0 0 $gridGutterDesktop * 2 $gridGutterDesktop * 2;
        padding: 0 0 $gridGutterDesktop * 2;

        border-bottom: 4px solid $midLightGray;
    }
}

.person-overview__name {
    margin: 0;
}

.person-overview__role {
    margin: 5px 0 0 0;

    &.is-highlighted {
        color: $orange;
    }
}

.person-overview__summary {
    margin-top: 30px;
}

.person-overview__summary__row {
    & + & {
        margin-top: 30px;
    }

    @include media('>=desktop') {
        display: flex;
        flex-direction: row;
    }
}

.person-overview__summary__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;

    .icon {
        margin: 0 15px 0 0;
        color: $green;
    }

    @include media('>=desktop') {
        flex-direction: column;
        margin-right: $gridGutterDesktop;
        min-width: 115px;

        .icon {
            margin: 0 0 5px 0;
        }
    }
}

.person-overview__summary__copy {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 20px;

    .icon {
        width: 30px;
        height: 30px;
    }

    .no-flex-gap & *:not(:first-child) {
        margin-left: 20px;
    }

    @include media('<desktop') {
        margin-top: 15px;
    }

    @include media('>=tablet') {
        .icon {
            width: 50px;
            height: 50px;
        }
    }
}

.person-overview__title {
    margin: 50px 0 20px 0;

    color: $green;

    + * {
        margin-top: 0;
    }

    @include media('>=desktop') {
        margin: 0 0 30px 0;
    }
}
