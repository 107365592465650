.hero-carousel {
    position: relative;
}

.hero-carousel__inner {
    @include clamp;
    display: flex;
    flex-direction: row;

    @include scrollable-x;
    scroll-snap-type: x mandatory;
}

.hero-carousel__item {
    flex: 0 0 100%;
    scroll-snap-align: start;
}

.hero-carousel__controls {
    position: absolute;
    top: 50vw;
    left: 0;
    right: 0;
    height: 0;
    z-index: 1;

    color: $white;

    .no-js & {
        display: none;
    }

    @include media('>=tablet') {
        top: 50%;
    }
}

.hero-carousel__button {
    @include unbutton;
    position: absolute;
    top: 0;
    margin-top: -25px;

    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }

    .icon {
        width: 50px;
        height: 30px;
    }

    &--prev {
        left: 0;

        .icon {
            transform: rotateZ(90deg);
        }
    }

    &--next {
        right: 0;

        .icon {
            transform: rotateZ(-90deg);
        }
    }

    @include media('>=tablet') {
        margin-top: -35px;

        .icon {
            width: 70px;
            height: 40px;
        }

        &--prev {
            left: 10px;
        }

        &--next {
            right: 10px;
        }
    }
}

.hero-carousel__pagination {
    position: absolute;
    top: calc(100vw - 20px);
    left: 0;
    right: 0;
    height: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    .no-js & {
        display: none;
    }

    @include media('>=tablet') {
        top: auto;
        bottom: 10px;
    }

    @include media('>=desktop') {
        bottom: 30px;
    }
}

.hero-carousel__pagination__list {
    @include delist;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
}

.hero-carousel__pagination__item {
}

.hero-carousel__pagination__button {
    @include unbutton;
    @include hide-text-alt;
    display: block;
    width: 20px;
    height: 20px;

    background: $midGray;
    border-radius: 50%;

    transition: background 0.2s ease-in-out;

    &:hover,
    &.is-active {
        background: $white;
    }
}
