@use 'sass:math';

.logo-card {
    position: relative;
    min-width: 240px;

    &::before {
        display: block;
        padding-top: calc(150px / 240px * 100%);
        content: '';
    }
}

.logo-card__link {
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.7;
    }
}

.logo-card__image {
    $padding: math.div(32, 240) * 100%;

    position: absolute;
    top: 0;
    right: $padding;
    bottom: 0;
    left: $padding;
    display: block;
    width: calc(100% - #{$padding * 2});
    height: 100%;
    object-fit: contain;
    object-position: center center;
}
