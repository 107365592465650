.news-list {
    @include media('<desktop') {
        margin-top: 50px;
    }
}

.news-list__list {
    @include delist;
}

.news-list__item {
    padding: 20px 0;

    border-top: 3px dashed $midGray;

    &:last-child {
        border-bottom: 3px dashed $midGray;
    }

    @include media('>=tablet') {
        padding: 40px 0;

        .news-list--mini & {
            padding: 20px 0;
        }
    }

    @include media('>=desktop') {
        padding: 50px 0;
    }
}
