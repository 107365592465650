.media-promo {
    background: $darkGray;

    color: $white;

    &--accent-green {
        background: $green;
    }

    &--accent-orange {
        background: $orange;
    }

    &--full-width {
        border-top: 2px solid $white;
        border-bottom: 2px solid $white;
    }
}

.media-promo__inner {
    @include clamp;
    @include gutter-padding;

    .media-promo > & {
        padding-top: 45px;
    }

    .media-promo--full-width > & {
        padding-top: 0;
    }
}

.media-promo__image-wrapper {
    position: relative;

    .media-promo--full-width & {
        margin: 0 calc(50% - 50vw);

        border-bottom: 1px solid $white;
    }
}

.media-promo__image,
.media-promo__image > img {
    display: block;
    width: 100%;
    height: auto;
}

.media-promo__overlay {
    @include media('>=tablet') {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include overlay-background;
    }
}

.media-promo__quote {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    blockquote {
        margin: 0;
        max-width: 800px;
    }

    p {
        margin: 0;
    }

    figcaption {
        margin: 15px 0 0 0;
        display: block;
        width: 100%;
        max-width: 800px;
    }

    cite {
        display: block;

        font-style: normal;
        text-align: center;
    }

    @include media('<desktop') {
        display: none;
    }
}

.media-promo__video-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.media-promo__main {
    padding: 25px 0 45px 0;

    @include media('>=tablet') {
        padding-top: 45px;
    }
}

.media-promo__play-button {
    @include unbutton;
    float: right;
    margin-left: 20px;

    .icon {
        width: 60px;
        height: 60px;
    }

    @include media('>=tablet') {
        .icon {
            width: 120px;
            height: 120px;
        }
    }
}

.media-promo__title {
    margin: 0;
}

.media-promo__authors {
    margin: 10px 0 0 0;
}

.media-promo__subtitle {
    margin: 10px 0 0 0;
}

.media-promo__footer {
    padding: 5px 0 45px 0;

    background: rgba(0, 0, 0, 0.05);

    .media-promo--full-width & {
        padding-bottom: 5px;

        border-top: 2px solid $white;
    }
}
