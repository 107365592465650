.disclosure-list {
    margin: 0 0 30px 0;

    text-align: left;

    @include media('>=tablet') {
        margin-bottom: 50px;
    }
}

.disclosure-list__list {
    @include delist;
}

.disclosure-list__item {
    padding: 10px 0;

    border-top: 2px solid;

    &:last-child {
        border-bottom: 2px solid;
    }

    .disclosure-list--accent-green & {
        border-color: $green;
    }

    .disclosure-list--accent-orange & {
        border-color: $orange;
    }

    @include media('>=tablet') {
        padding: 20px;
    }
}

.disclosure-list__title {
    margin: 0;
}

.disclosure-list__button {
    @include unbutton;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    text-align: left;

    .icon {
        flex-shrink: 0;
        width: 25px;
        height: 13px;
        margin-top: 0.25em;
        margin-right: 15px;
    }

    &.is-open .icon {
        transform: rotateZ(180deg);
    }

    .disclosure-list--accent-green & {
        color: $green;
    }

    .disclosure-list--accent-orange & {
        color: $orange;
    }

    @include media('>=tablet') {
        .icon {
            width: 35px;
            height: 18px;
            margin-top: 0.35em;
            margin-right: 25px;
        }
    }
}

.disclosure-list__description {
    display: none;
    margin: 20px 0 10px 0;
    padding: 0 0 0 (25px + 15px);

    &.is-open {
        display: block;
    }

    @include media('>=tablet') {
        padding-left: (35px + 25px);
    }
}
