@mixin scrollable-x() {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-x: contain;
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
        display: none;
    }

    @include media('>=desktop') {
        touch-action: pan-x;
    }
}

@mixin hide-text-alt() {
    font: 0/0 a; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

@mixin unbutton() {
    padding: 0;

    border: 0;
    border-radius: 0;
    outline: 0;

    background: transparent;

    cursor: pointer;
    color: inherit;
    text-align: inherit;
    appearance: none;
}

@mixin delist() {
    margin: 0;
    padding: 0;

    list-style: none;
}

@mixin clamp() {
    max-width: $layoutMaxWidth;
    margin-left: auto;
    margin-right: auto;
}

@mixin gutter-margin() {
    margin-left: $pageGutterMobile;
    margin-right: $pageGutterMobile;

    @include media('>=tablet') {
        margin-left: $pageGutterTablet;
        margin-right: $pageGutterTablet;
    }

    @include media('>=desktop') {
        margin-left: $pageGutterDesktop;
        margin-right: $pageGutterDesktop;
    }
}

@mixin gutter-padding($vertical: false) {
    padding-left: $pageGutterMobile;
    padding-right: $pageGutterMobile;

    @if $vertical {
        padding-top: $pageGutterMobile;
        padding-bottom: $pageGutterMobile;
    }

    @include media('>=tablet') {
        padding-left: $pageGutterTablet;
        padding-right: $pageGutterTablet;

        @if $vertical {
            padding-top: $pageGutterTablet;
            padding-bottom: $pageGutterTablet;
        }
    }

    @include media('>=desktop') {
        padding-left: $pageGutterDesktop;
        padding-right: $pageGutterDesktop;

        @if $vertical {
            padding-top: $pageGutterDesktop;
            padding-bottom: $pageGutterDesktop;
        }
    }
}

@mixin grid() {
    @include gutter-margin;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-column-gap: $gridGutterMobile;
    grid-row-gap: $gridGutterMobile;

    @include media('>=tablet') {
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: $gridGutterTablet;
        grid-row-gap: $gridGutterTablet;
    }

    @include media('>=desktop') {
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: $gridGutterDesktop;
        grid-row-gap: $gridGutterDesktop;
    }
}

@mixin overlay-background() {
    background: linear-gradient(181.06deg, rgba(82, 50, 21, 0.85) -41.04%, rgba(117, 80, 47, 0.0555143) 17.28%, rgba(117, 80, 47, 0) 77.98%, rgba(82, 50, 21, 0.78) 146.43%);
    mix-blend-mode: multiply;
    opacity: 0.6;

    &--light {
        // (default)
    }

    &--medium {
        background: linear-gradient(181.06deg, rgba(94, 52, 15, 0.98) -34.17%, rgba(94, 62, 33, 0.0355) 28.02%, rgba(102, 61, 25, 0.1) 65.5%, rgba(98, 52, 11, 0.98) 109.82%);
    }

    &--heavy {
        background: linear-gradient(179.41deg, rgba(145, 80, 21, 0.98) -11.55%, rgba(111, 70, 32, 0.344764) 12.39%, rgba(104, 67, 34, 0.2012) 41.99%, rgba(57, 34, 12, 0.95) 75.53%);
    }
}
