.content-section {
    text-align: center;

    &--alt-bg {
        background: $lighterGray;
    }

    &:not(:first-child) {
        margin-top: 20px;
    }

    &:last-of-type {
        padding-bottom: 30px;
    }

    .hero + & {
        margin-top: 0 !important;
    }

    @include media('>=tablet') {
        &:not(:first-child) {
            margin-top: 40px;
        }

        &:last-of-type {
            padding-bottom: 60px;
        }
    }
}

.content-section__inner {
    @include clamp;
    @include gutter-padding;
    padding-top: 20px;
    padding-bottom: 20px;

    @include media('>=tablet') {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include media('>=desktop') {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.content-section__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;

    .icon {
        flex-shrink: 0;
        width: 70px;
        height: 70px;

        .content-section--accent-green & {
            color: $green;
        }

        .content-section--accent-orange & {
            color: $orange;
        }

        .no-flex-gap & {
            margin-bottom: 15px;
        }
    }

    @include media('>=tablet') {
        margin-bottom: 20px;
    }

    @include media('>=desktop') {
        margin-bottom: 30px;
    }
}

.content-section__title {
    margin: 0 auto;

    .content-section--title-green & {
        color: $green;
    }

    .content-section--title-orange & {
        color: $orange;
    }

    @include media('>=tablet') {
        max-width: 620px;
    }

    @include media('>=desktop') {
        max-width: 700px;
    }
}

.content-section__subtitle {
    margin: 0 auto;

    .content-section--accent-green & {
        color: $green;
    }

    .content-section--accent-orange & {
        color: $orange;
    }

    @include media('>=tablet') {
        max-width: 620px;
    }

    @include media('>=desktop') {
        max-width: 700px;
    }
}

.content-section__main {
}

.content-section__standfirst {
    margin-bottom: 20px;

    &:empty {
        display: none;
    }

    ul,
    ol {
        @include delist;
        margin-top: 20px !important;
    }

    li {
        margin-top: 20px;
    }

    p,
    ul {
        margin-left: auto;
        margin-right: auto;
        max-width: 620px;
    }

    @include media('>=desktop') {
        margin-bottom: 50px;

        p,
        ul {
            max-width: 850px;
        }
    }
}

.content-section__content {
    &:empty {
        display: none;
    }

    .carousel,
    .logo-carousel,
    .image-grid {
        margin-top: 25px;
    }

    blockquote {
        .content-section--accent-green &::before,
        .content-section--accent-green &::after {
            color: $green;
        }

        .content-section--accent-orange &::before,
        .content-section--accent-orange &::after {
            color: $orange;
        }
    }

    cite {
        .content-section--accent-green & {
            color: $green;
        }

        .content-section--accent-orange & {
            color: $orange;
        }
    }

    @include media('>=tablet') {
        .carousel,
        .logo-carousel,
        .image-grid {
            margin-top: 40px;
        }
    }
}

// TODO: Get rid of this?
.content-section__extras {
    p,
    ul {
        margin-left: auto;
        margin-right: auto;
        max-width: 620px;
    }

    @include media('>=desktop') {
        p,
        ul {
            max-width: 850px;
        }
    }
}

.content-section__footer {
    margin-top: 25px;

    &:empty {
        display: none;
    }

    .button {
        .content-section--accent-green & {
            color: $green;
        }

        .content-section--accent-orange & {
            color: $orange;
        }
    }

    @include media('>=tablet') {
        margin-top: 40px;
    }
}
