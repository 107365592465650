// Colours ////////////////////////////////////////////////////////////////////
$black: #000;
$white: #fff;

$lighterGray: #fafafa;
$lightGray: #f5f5f5;
$midLightGray: #e4e4e4;
$midGray: #b5b5b5;
$darkGray: #414042;

$orange: #ff9e4d;
$green: #6fa13e;


// Fonts //////////////////////////////////////////////////////////////////////
$fontStackBody: 'Montserrat', 'Trebuchet MS', Helvetica, sans-serif;
$fontStackHeading: 'Playfair Display', Baskerville, Times, 'Times New Roman', serif;


// Units //////////////////////////////////////////////////////////////////////
$baseFontSize: 26px;
$baseLineHeightRatio: 1.2;

$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemibold: 600;
$fontWeightBold: 700;


// Layout and include-media config ////////////////////////////////////////////
$layoutMaxWidth: 1600px;
$innerMaxWidth: 1200px;

$breakpoints: (
    mobile: 320px,
    tablet: 768px,
    desktop: 1024px,
);

$pageGutterMobile: 20px;
$pageGutterTablet: 60px;
$pageGutterDesktop: 120px;

$gridGutterMobile: 20px;
$gridGutterTablet: 20px;
$gridGutterDesktop: 20px;

$stickyHeaderHeightMobile: 65px;
$stickyHeaderHeightTablet: 124px;
$stickyHeaderHeightDesktop: 124px;
// XXX: InPageNav not sticky on mobile at this point
$stickyHeaderWithInPageNavHeightTablet: 240px;
$stickyHeaderWithInPageNavHeightDesktop: 224px;


// Layers /////////////////////////////////////////////////////////////////////
$z-header: 10;
$z-in-page-nav: 9;
$z-normal: 1;
