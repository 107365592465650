.facet-grid {
    background: $lighterGray;

    text-align: center;
}

.facet-grid__inner {
    @include clamp;
    @include gutter-padding;
    padding-top: 50px;
    padding-bottom: 50px;

    @include media('>=desktop') {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}

.facet-grid__list {
    @include delist;
    display: flex;
    flex-direction: column;
    gap: $gridGutterMobile * 2 $gridGutterMobile;

    @include media('>=tablet') {
        gap: $gridGutterTablet * 3 $gridGutterTablet;
    }

    @include media('>=desktop') {
        flex-flow: row wrap;
        align-items: flex-start;
        gap: $gridGutterDesktop * 3 $gridGutterDesktop;

        .no-flex-gap & {
            margin: #{$gridGutterDesktop * -0.5};
        }
    }
}

.facet-grid__item {
    @include media('<tablet') {
        .no-flex-gap & + & {
            margin-top: $gridGutterMobile;
        }
    }

    @include media('<desktop') {
        .no-flex-gap & + & {
            margin-top: $gridGutterTablet;
        }
    }

    @include media('>=desktop') {
        flex-basis: calc(50% - (#{$gridGutterDesktop} * 1 / 2));

        .no-flex-gap & {
            margin: calc(#{$gridGutterDesktop} * 3 / 2) #{$gridGutterDesktop *
                0.25};
        }
    }
}

.facet-grid__icon {
    margin-bottom: 10px;
    width: 80px;
    height: 80px;

    @include media('>=tablet') {
        margin-bottom: 0;
    }

    @include media('>=desktop') {
        width: 100px;
        height: 100px;
    }
}

.facet-grid__title {
    margin: 0;

    .facet-grid--title-green & {
        color: $green;
    }

    .facet-grid--title-orange & {
        color: $orange;
    }

    @include media('>=tablet') {
        margin: 0 $gridGutterTablet;
    }

    @include media('>=desktop') {
        margin: 0 $gridGutterDesktop;
    }
}

.facet-grid__text {
    margin: 20px auto 0 auto;
    max-width: 540px;

    @include media('>=tablet') {
        margin-top: 30px;
    }
}

.facet-grid__footer {
    margin-top: 20px;

    @include media('>=desktop') {
        margin-top: 50px;
    }
}
