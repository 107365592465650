.news-hero {
    background: $darkGray;

    color: $white;
}

.news-hero__inner {
    @include clamp;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;

    &::before {
        display: block;
        padding-top: calc(320px / 1435px * 100%);
        content: '';
    }
}

.news-hero__image,
.news-hero__image > img {
    display: block;
    width: 100%;
    height: auto;
}

.news-hero__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.news-hero__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include overlay-background;
}

.news-hero__content {
    @include gutter-padding;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;

    @include media('>=tablet') {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include media('>=desktop') {
        padding-top: 65px;
        padding-bottom: 65px;
    }
}

.news-hero__headline {
    margin: 0 auto 0 0;
}
