.news-card {
    text-align: left;
}

.news-card__header {
    display: flex;
    flex-direction: column;

    @include media('>=tablet') {
        flex-direction: row;
    }
}

.news-card__date {
    @include media('<tablet') {
        color: $midGray;
    }

    @include media('>=tablet') {
        flex-basis: 6em;
        flex-shrink: 0;
    }
}

.news-card__title {
    margin: 0;
    flex-grow: 1;
}

.news-card__title__link {

}

.news-card__type {
    margin: 10px 0 0 0;

    color: $orange;

    @include media('>=tablet') {
        flex-shrink: 0;
        margin: 0 0 0 1em;
    }
}

.news-card__body {
    margin: 20px 0 0 0;

    .news-card--mini & {
        display: none;
    }

    @include media('>=desktop') {
        margin-top: 40px;
    }
}

.news-card__footer {
    margin: 20px 0 0 0;

    .news-card--mini & {
        display: none;
    }

    @include media('>=desktop') {
        margin-top: 40px;
    }
}
