.hero {
    @include media('>=tablet') {
        position: relative;
        z-index: $z-normal;

        color: $white;
    }
}

.hero__inner {
    @include clamp;
    margin-top: 0;
    margin-bottom: 0;
}

.hero__image,
.hero__image > img {
    display: block;
    width: 100%;
    height: auto;
}

.hero__overlay {
    @include media('>=tablet') {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include overlay-background;
    }
}

.hero__content {
    @include gutter-padding;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    text-align: center;

    @include media('>=tablet') {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-top: 40px;
        padding-bottom: 40px;
        align-items: flex-start;

        text-align: left;
    }

    @include media('>=desktop') {
        padding-top: 65px;
        padding-bottom: 65px;
    }
}

.hero__quote {
    margin: 0 auto 0 0;
    padding: 0;

    text-align: center;

    @include media('>=desktop') {
        max-width: 580px;
    }
}

.hero__citation {
    display: block;
    margin: 20px auto 0 auto;
    width: 100%;

    font-style: normal;
    text-align: center;

    @include media('>=desktop') {
        max-width: 580px;
    }
}

.hero__headline {
    margin: 0;

    i,
    em {
        font-style: italic;
    }

    b,
    strong {
        font-weight: bold;
    }

    mark {
        display: block;
        margin-top: -0.1em;

        background: transparent;

        font-size: 70px;
        line-height: 0.8;
        color: inherit;
    }

    .hero--highlight-marks & b,
    .hero--highlight-marks & strong,
    .hero--highlight-marks & mark {
        color: $orange;
    }

    @include media('>=tablet') {
        margin: 0 auto 0 0;
        max-width: 670px;

        mark {
            font-size: 100px;
        }
    }
}

.hero__standfirst {
    margin: 0 auto 0 0;
    max-width: 630px;

    .hero__headline + & {
        margin-top: 15px;
    }
}

.hero__cta {
    margin-top: 20px;

    @include media('>=desktop') {
        margin-top: 40px;
    }
}
